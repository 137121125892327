/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 */
@import 'tailwindcss/base';

/* purgecss start ignore */

/**
 * Here we add custom base styles, applied after the tailwind-base
 * classes
 *
 */

/* purgecss end ignore */

@layer base {
    html,
    body {
        height: 100%;
    }

    body {
        font-size: theme('fontSize.base');

        &.is-editing {
            main {
                height: calc(100vh - var(--header-height));
                height: calc(100dvh - var(--header-height));
            }

            .dropzone,
            .mini-footer {
                display: none;
            }
        }
    }

    main {
        min-height: calc(100vh - var(--header-height));
        min-height: calc(100dvh - var(--header-height));
    }

    [hidden] {
        display: none !important;
    }
}