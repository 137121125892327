/**
 * components/croptool.css
 * 
 * The Croptool component.
 *
 */

.croptool {
    .cropper {
        &-modal {
            // background-color: theme('colors.warm-neutral');
            // opacity: 0.6;
        }

        &-crop-box {
            // filter: drop-shadow(0px 0px 8px black);

            .cropper-face {
                background: none;
                opacity: 1 !important;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }

        &-view-box {
            position: relative;
            outline: none;
        }
    }

    input[type="range"] {
        appearance: none;
        width: 100%;
        height: 10px;
        border-radius: 10px;
        background: #d3d3d3;
        outline: none;

        &:hover {
            background: #ccc;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: theme('colors.secondary');
            cursor: pointer;
          }
          
        &::-moz-range-thumb {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            background: theme('colors.secondary');
            cursor: pointer;
        }
    }

    .custom-radio {
        position: relative;
        padding-left: 30px;
        line-height: 24px;
        cursor: pointer;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked ~ .checkmark {
                background-color: theme('colors.secondary') !important;

                &::after {
                    display: block;
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 1px;
            left: 0;
            height: 22px;
            width: 22px;
            background-color: #d3d3d3;
            border-radius: 50%;

            &::after {
                content: '';
                position: absolute;
                display: none;
                top: 6px;
                left: 6px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: white;
            }
        }

        &:hover input ~ .checkmark {
            background-color: #ccc;
        }
    }
}
