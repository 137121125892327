/**
 * components/dropzone.css
 * 
 * The Dropzone component.
 *
 */

.dropzone {
    outline: 2px dashed theme('colors.white / 40%');
    outline-offset: -1rem;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;

    &.is-dragover {
        outline-offset: -2rem;
        outline-color: theme('colors.white / 100%');
        background-color: theme('colors.white');
    }
}
