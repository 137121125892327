/**
 * This injects any component classes registered by plugins.
 *
 */
@import 'tailwindcss/components';

/* purgecss start ignore */

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */
@import './components/dropzone.css';
@import './components/selectors.css';
@import './components/croptool.css';

/* purgecss end ignore */

@layer components {}
