/**
 * components/webfonts.css
 * 
 * Project webfonts.
 *
 */

/*@font-face {
  font-family: 'Embrace-Giant';
  src: url(../../assets/webfonts//Embrace_a_Giant_Spirit_Font.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap
}*/