/**
 * vendor.css
 *
 * All vendor CSS is imported here.
 *
 */

@import 'tom-select/dist/css/tom-select.default.css';
@import 'cropperjs/dist/cropper.css';

.ts-dropdown .active {
    background-color: theme('colors.primary');
    color: theme('colors.white');
}
.ts-wrapper.single .ts-control {
    --ts-pr-clear-button: -5px;
    box-shadow: none;
    background: transparent;
    border: 1px solid #73757a;
    border-radius: 8px;

    @screen lg {
        font-size: 16px;
    }

    &:not(.rtl)::after {
        right: 10px;
    }

    &:focus {
        outline: none !important;
    }
}

body #iubenda-iframe {    
    #iubFooterBtnContainer button {
        background-color: theme('colors.primary') !important;
        color: theme('colors.white') !important;
    }
}