/**
 * components/selector.css
 * 
 * The Selector component.
 *
 */

.selector {
    .optgroup-header {
        background: theme('colors.gray-200');
        text-transform: uppercase;
    }
    .option,
    .item {
        .tag {
            border: 1px solid currentColor;
            flex: 0 0 auto;
        }
        .role {
            display: none;
            text-align: center;
            font-size: 10px;
            line-height: 16px;

            @screen md {
                display: inline-block;
            }

            &::before {
                content: 'ROLE';
            }
        }
    }
}
